<template>
    <div class="main-page">
        <!-- 通知公告 start -->
        <div class="notice-wrapper main flex-between">
            <img src="@/assets/images/main/notice.png" alt="" class="notice-img">
            <button class="notice-more csp"
                @click="$router.push({path:'/newswork',query:{projectId:$route.params.id}})">更多<i
                    class="el-icon-arrow-right"></i></button>
            <ul class="notice-list" v-if="firstNotice && JSON.stringify(firstNotice) != '{}'">
                <li class="notice-first-item flex-align csp"
                    @click="$router.push({path:'/news/notice',query:{projectId:$route.params.id,id:firstNotice.id}})">
                    <div class="first-item-time">
                        <p class="first-item-day">{{dayjs(firstNotice.updateTime).format('DD')}}</p>
                        <p class="first-item-year">{{dayjs(firstNotice.updateTime).format('YYYY.MM')}}</p>
                    </div>
                    <div class="first-item-news">
                        <h5 class="first-item-title wes-2">{{firstNotice.title}}</h5>
                        <p class="wes-2 first-item-desc">{{firstNotice.introduction || ''}}</p>
                    </div>
                </li>
                <li v-for="item in noticeList" :key="item.id" class="flex-align-between notice-item csp"
                    @click="$router.push({path:'/news/notice',query:{projectId:$route.params.id,id:item.id}})">
                    <h6 class="notice-item-titel wes"><i class="dot"></i>{{item.title}}</h6>
                    <span class="notice-item-time">{{dayjs(item.updateTime).format('YYYY-MM-DD')}}</span>
                </li>
            </ul>
            <Empty :show="firstNotice && JSON.stringify(firstNotice) == '{}'"></Empty>
        </div>
        <!-- 通知公告 end -->
        <!-- 项目简报 start -->
        <div class="bulletin-wrapper main flex-between">
            <div class="bulltin">
                <h3 class="bulltin-list-title flex-between">
                    <span><i class="bulltin-icon"></i>项目简报</span>
                    <button class="more" @click="$router.push(`/bulletin/${$route.params.id}`)">更多<i
                            class="el-icon-arrow-right"></i></button>
                </h3>
                <ul class="bulltin-list" v-if="bulltinList && bulltinList.length !== 0">
                    <li v-for="item in bulltinList" :key="item.id" class="bulltin-list-row csp flex-between"
                        @click="toPdfPage(item)">
                        <h6><i class="dot"></i>{{item.name}}</h6>
                        <span class="bulltin-item-time">{{dayjs(item.createTime).format('YYYY-MM-DD')}}</span>
                    </li>
                </ul>
                <Empty :show="bulltinList && bulltinList.length === 0"></Empty>
            </div>
            <ul class="bulltin-rules flex-column">
                <li v-for="item in bulltinRules" :key="item.id" class="bulltin-rules-item flex-between csp"
                    @click="toPdfPage(item)">
                    <div class="flex-align">
                        <img :src="item.icon" alt="" class="rules-item-icon">
                        <h5>{{item.title}}</h5>
                    </div>
                    <button class="rules-item-btn csp">
                        <img :src="item.btn" alt="">
                    </button>
                </li>
            </ul>
        </div>
        <!-- 项目简报 end -->
        <!-- 优秀作业 start -->
        <div class="work-wrapper main flex-between">
            <div class="work">
                <h3 class="work-list-title flex-between">
                    <span><i class="work-icon"></i>优秀作业</span>
                    <button class="more" @click="$router.push(`/workList/${$route.params.id}`)">更多<i
                            class="el-icon-arrow-right"></i></button>
                </h3>
                <ul class="work-list" v-if="workList && workList.length !== 0">
                    <li v-for="(item,index) in workList" :key="index" class="work-list-row csp flex-between"
                        @click="openWorkDialog(item)">
                        <h6 class="wes"><i class="dot"></i>{{item.workName}}</h6>
                        <span class="wes">{{item.schoolName || '--'}}</span>
                        <span class="wes">{{item.userName}}</span>
                        <span
                            class="work-item-time wes">{{ item.correctingTime? dayjs(item.correctingTime).format('YYYY-MM-DD') : '--'}}</span>
                    </li>
                </ul>
                <Empty :show="workList && workList.length === 0"></Empty>
            </div>
            <div class="work-rank">
                <h4 class="work-rank-title"><i class="rank-icon"></i>研修排行榜</h4>
                <ul class="work-rank-list" v-if="workRank && workRank.length !== 0">
                    <li v-for="(item,index) in workRank" :key="item.userId" class="work-rank-item flex-align">
                        <i class="rank-no">{{index+1 > 3? index+1 : ''}}</i>
                        <img :src="downloadURL + item.avatar" alt="" class="rank-avatar"
                            :onerror="$store.state.user.defaultAvatar">
                        <div>
                            <p class="rank-name">{{item.userName}}</p>
                            <p class="rank-school">{{item.school}}</p>
                        </div>
                    </li>
                </ul>
                <Empty :show="workRank && workRank.length === 0"></Empty>
            </div>
        </div>
        <!-- 优秀作业 end -->
        <!-- 研修成果展示 start -->
        <div class="result-wrapper">
            <div class="main result-inner flex-align">
                <h3 class="result-title">研修成果展示</h3>
                <div class="result-data flex-align">
                    <div class="flex-align result-data-item">
                        <div class="result-icon-wrap">
                            <img src="@/assets/images/main/result-icon1.png" alt="" class="result-data-icon">
                        </div>
                        <div>
                            <p class="result-number">{{questCount}}<i class="result-unit">个</i></p>
                            <p class="result-name">作业数量</p>
                        </div>
                    </div>
                    <div class="flex-align result-data-item">
                        <div class="result-icon-wrap">
                            <img src="@/assets/images/main/result-icon2.png" alt="" class="result-data-icon">
                        </div>
                        <div>
                            <p class="result-number">{{courseCount}}<i class="result-unit">个</i></p>
                            <p class="result-name">课程数量</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <!-- 研修成果展示 end -->
        <!-- 精品课程&云端答疑 start -->
        <div class="course-wrapper main flex-between">
            <div class="course">
                <h3 class="course-title flex-align-between">
                    <span><i class="course-icon"></i>精品课程</span>
                    <button class="more" @click="$router.push(`/qualityCourse/${$route.params.id}`)">更多<i
                            class="el-icon-arrow-right"></i></button>
                </h3>
                <ul class="course-list flex-layout" v-if="courseList && courseList.length !== 0">
                    <li v-for="(item,index) in courseList" :key="item.id" class="course-item csp"
                        @click="toDeatil(item,index)">
                        <img :src="downloadURL + item.cover" alt="" class="course-cover"
                            :onerror="$store.state.course.defaultCourseCover[index % 6]">
                        <div class="course-desc">
                            <h5 class="wes-2">{{item.name}}</h5>
                            <p class="flex-layout">
                                <span style="minWidth:61px;">主讲教师：</span>
                                <span>{{item.teacherName}}</span>
                            </p>
                            <p class="flex-layout">
                                <span style="minWidth:61px;">上课时间：</span>
                                <span>{{item.timeScope}}</span></p>
                        </div>
                    </li>
                </ul>
                <Empty :show="courseList && courseList.length === 0"></Empty>
            </div>
            <div class="cloud-qa">
                <h3 class="course-title flex-align-between">
                    <span><i class="cloud-icon"></i>云端答疑</span>
                    <button class="more" @click="toQuestList">更多<i class="el-icon-arrow-right"></i></button>
                </h3>
                <ul class="cloud-list" v-if="questionList && questionList.length !== 0">
                    <li v-for="item in questionList" :key="item.id" class="flex-between cloud-item"
                        @click="toQuestionDetail(item)">
                        <p class="wes-2">{{item.content}}<span
                                class="cloud-time">{{item.createTime ? dayjs(item.createTime).format('YYYY-MM-DD'):'--'}}</span>
                        </p>
                        <button class="cloud-replay csp">回复</button>
                    </li>
                </ul>
                <Empty :show="questionList && questionList.length === 0"></Empty>
            </div>
        </div>
        <!-- 精品课程&云端答疑 end -->
        <!-- 学情统计 start -->
        <div class="learning-count-wrapper main">
            <h3 class="learning-title flex-align-between">
                <span><i class="learning-icon"></i>学情统计</span>
                <p class="learning-time">
                    <span>最新更新时间：<i>{{dayjs().format('YYYY年MM月DD日')}} 00:00</i></span>
                    <!-- <span>距离下次更新还有：<i>06小时58分钟</i></span> -->
                </p>
            </h3>
            <div class="learning-count">
                <h5 class="tc learning-count-title">{{learningTitle}}</h5>
                <ul class="learning-count-list flex-align-between">
                    <li v-for="(item,index) in learningList" :key="index" class="learning-count-item flex-align">
                        <i class="learning-data-icon"></i>
                        <div>
                            <p class="learning-data-name">{{item.name}}</p>
                            <p class="learning-data-number" v-if="item.name=='参训率'">{{(item.number*100).toFixed()}}
                                <span v-if="item.name=='参训率'" class="learning-data-unit">%</span>
                                <span v-if="item.name=='课程学习时长'" class="learning-data-unit">min</span>
                            </p>
                            <p class="learning-data-number" v-else>{{(item.number).toFixed()}}
                                <span v-if="item.name=='参训率'" class="learning-data-unit">%</span>
                                <span v-if="item.name=='课程学习时长'" class="learning-data-unit">min</span>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="learning-table">
                <div class="table-switch">
                    <button class="table-switch-btn" :class="{'tab-active':tableSwitch}"
                        @click="switchTableData(1)">区县学情</button>
                    <button class="table-switch-btn" :class="{'tab-active':!tableSwitch}"
                        @click="switchTableData(0)">学校学情</button>
                </div>
                <div class="table-wrap">
                    <transition name="draw">
                        <div class="tansition-box" :style="{height:tableHeight}">
                            <div class="trueTable" ref="trueTable">
                                <el-table :data="tableData" style="width: 100%">
                                    <el-table-column prop="itemName" label="名称" width="220">
                                    </el-table-column>
                                    <el-table-column prop="userCount" label="学生人数" align="center">
                                    </el-table-column>
                                    <el-table-column prop="studyUserCount" label="已学习人数" align="center">
                                    </el-table-column>
                                    <el-table-column prop="joinRate" label="参训率" align="center">
                                        <template slot-scope="scope">
                                            {{scope.row.joinRate * 100}}%
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="questCount" :label="'作业提交数量\n(单位：个)'" align="center">
                                    </el-table-column>
                                    <el-table-column prop="learnTime" :label="'课程学习时长\n(单位：min)'" align="center">
                                        <template slot-scope="scope">
                                            {{parseInt(scope.row.learnTime / 60)}}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </transition>
                    <button class="open-table-btn csp" v-if="openText" @click="openTable">{{openText}} <i
                            :class="[openText =='展开'?'el-icon-arrow-down':'el-icon-arrow-up']"></i></button>
                </div>

            </div>
        </div>
        <!-- 学情统计 end -->
        <!-- 项目回顾 start -->
        <div class="review-wrapper">
            <h3 class="review-title main">
                <i class="review-icon"></i>项目回顾
            </h3>
            <div class="review-carousel-wrap">
                <el-carousel class="review-carousel" :autoplay="false" @change="changeVideo" arrow="always"
                    v-if="JSON.stringify(reviewVideoList) != '[]'">
                    <el-carousel-item v-for="(item,index) in reviewVideoList" :key="item.id">
                        <div class="video-wrapper">
                            <video :src="downloadURL + item.identifier" controls class="carousel-img"
                                disablePictureInPicture controlslist="nodownload" :id="`reviewVideo${index}`"></video>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <img src="@/assets/images/main/video-empty.png" alt="" v-if="JSON.stringify(reviewVideoList) == '[]'"
                    class="video-empty">
            </div>
        </div>
        <!-- 项目回顾 end -->
        <!-- 项目专家 start -->
        <div class="experts-wrapper main">
            <h3 class="experts-title">
                <i class="experts-icon"></i>项目专家
            </h3>
            <el-carousel indicator-position="none" arrow="always" :autoplay="false" class="expert-carousel" type="card"
                v-if="expertList && expertList.length !== 0">
                <el-carousel-item v-for="item in expertList" :key="item.id">
                    <div class="expert-item-inner">
                        <div><img :src="downloadURL + item.cover" alt="" class="expert-avatar"
                                :onerror="$store.state.user.rectAvatar"></div>
                        <div class="expert-item-info">
                            <p class="expert-name wes">{{item.name}}</p>
                            <p class="expert-introduce wes-3">{{item.intro || '暂无简介'}}</p>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <Empty :show="expertList && expertList.length === 0"></Empty>
        </div>
        <!-- 项目专家 end -->
        <!-- 优秀作业弹窗 start-->
        <el-dialog :title="`优秀作品 —— ${currentWork.userName}`" :visible.sync="showWorkDialog" class="work-dialog">
            <p class="work-title">【{{currentWork.workName}}】</p>
            <StuWork :currentWork='currentWork'></StuWork>
            <span slot="footer" class="dialog-footer">
                <el-button @click="prev">上一个</el-button>
                <el-button type="primary" @click="next">下一个</el-button>
            </span>
        </el-dialog>
        <div id="output"></div>
        <!-- 优秀作业弹窗 end-->
    </div>
</template>

<script>
    import StuWork from '../index/stuwork.vue'
    import {
        deepClone,
    } from "@/utils/index";
    export default {
        components: {
            StuWork
        },
        data() {
            return {
                show3: true,
                showWorkDialog: false,
                currentWork: {
                    workName: '陈独秀'
                },
                tableSwitch: 1,
                noticeList: [],
                firstNotice: {},
                expertList: [],
                currentBulltin: {}, // 当前选中的项目简报
                bulltinList: [], // 项目简报列表
                bulltinRules: [{ //项目事实方案等
                        id: 1,
                        title: '实施方案',
                        icon: require("@/assets/images/main/rule-icon1.png"),
                        btn: require("@/assets/images/main/right1.png"),
                        identifier: '',
                        type: null,
                    },
                    {
                        id: 2,
                        title: '考核评价',
                        icon: require("@/assets/images/main/rule-icon2.png"),
                        btn: require("@/assets/images/main/right2.png"),
                        identifier: '',
                        type: null,
                    },
                    {
                        id: 3,
                        title: '操作指南',
                        icon: require("@/assets/images/main/rule-icon3.png"),
                        btn: require("@/assets/images/main/right3.png"),
                        identifier: '',
                        type: null,
                    },
                    // {
                    //     id: 4,
                    //     title: '项目类型',
                    //     icon: require("@/assets/images/main/rule-icon4.png"),
                    //     btn: require("@/assets/images/main/right4.png"),
                    //     identifier: '',
                    //     type: null,
                    // }
                ],
                workList: [],
                workRank: [],
                courseList: [],
                discussId: null,
                discussInfo: null,
                questionList: [],
                tableData: [],
                tableHeight: 'auto',
                openText: undefined,
                learningTitle: '',
                learningList: [{
                        name: '学员人数',
                        number: 0,
                        title: 'userCount'
                    },
                    {
                        name: '已学习人数',
                        number: 0,
                        title: 'studyUserCount'
                    },
                    {
                        name: '参训率',
                        number: 0,
                        title: 'joinRate'
                    },
                    {
                        name: '作业提交数量',
                        number: 0,
                        title: 'questCount'
                    },
                    {
                        name: '课程学习时长',
                        number: 0,
                        title: 'learnTime'
                    },
                ],
                reviewVideoList: [],
                periodId: null,
                courseCount: 0,
                questCount: 0,
                limit: 5,
                currentPage: 1,
                totalPage: null,
                allWorkList: [],
                currentIndex: 0,
                info: null,
            }
        },
        methods: {
            // 展示优秀作业弹窗
            openWorkDialog(item) {
                // console.log(item)
                this.showWorkDialog = true;
                let index = this.allWorkList.findIndex(items => items.userId == item.userId);
                this.currentIndex = index;
                this.currentWork = this.allWorkList[this.currentIndex];
                if (this.currentIndex == this.allWorkList.length - 1) {
                    if (this.currentPage == this.totalPage) {
                        return
                    } else {
                        this.currentPage += 1;
                        this.getWorkList()
                            .then(res => {
                                console.log(res);
                                this.currentIndex += 1;
                                this.currentWork = this.allWorkList[this.currentIndex];
                            })

                    }
                }
            },
            // 获取通知公告
            async getNoticeList() {
                let params = {
                    projectId: this.$route.params.id,
                    page: 1,
                    limit: 4,
                }
                let resData = await this.$Api.Home.getNewsListByProjectId(params);
                // console.log(resData);
                this.noticeList = deepClone(resData.data.list);
                this.firstNotice = resData.data.list[0];
                this.noticeList.shift();
            },
            //获取二级项目数据（期）
            async getSecondList() {
                let resData = await this.$Api.DST.getlistBySecondId(this.$route.params.id);
                if (JSON.stringify(resData.data) == '[]' || !resData) return;
                // console.log(resData);
                this.periodId = resData.data[0].id
                this.getOtherTeacher();
            },
            // 获取项目组其他专家列表
            async getOtherTeacher() {
                let data = {
                    projectId: this.$route.params.id,
                    page:1,
                    limit:999,
                    type:'1'
                };
                let resData = await this.$Api.DST.getOtherTeacher(data);
                // console.log('专家列表',resData);
                this.expertList = resData.data.records;
            },
            // 获取项目简报
            async getProjectBriefing() {
                this.bulltinList = [];
                let params = {
                    projectId: this.$route.params.id,
                    page: 1,
                    limit: 5,
                }
                let resData = await this.$Api.Project.getProjectBriefing(params);
                // console.log(resData);
                if (JSON.stringify(resData.data.records) == '[]' || !resData) return;
                this.bulltinList = resData.data.records
            },
            // 获取项目说明文件
            async getProjectFile() {
                this.reviewVideoList = [];
                this.bulltinRules.map(item => {
                    item.type = null;
                    item.identifier = null;
                })
                let params = {
                    projectId: this.$route.params.id,
                    // projectId: 194,
                }
                let resData = await this.$Api.Project.getProjectFile(params);
                // console.log('getProjectFile', resData);
                if (JSON.stringify(resData.data) == '{}' || !resData) return;
                this.bulltinRules.map(item => {
                    if (resData.data[item.title] && JSON.stringify(resData.data[item.title]) != '[]') {
                        item.type = resData.data[item.title][0].type;
                        item.identifier = resData.data[item.title][0].identifier;
                    }
                })
                this.reviewVideoList = resData.data['项目回顾']
                this.$nextTick(() => {
                    // this.getVideoPoster();
                })
            },
            changeVideo(index, prevIndex) {
                let prevVideo = document.getElementById(`reviewVideo${prevIndex}`);
                // let currentVideo = document.getElementById(`reviewVideo${index}`);
                prevVideo.pause();
                // currentVideo.play();
            },
            // 截取视频第一帧作为视频封面
            getVideoPoster() {
                let video, output;
                output = document.getElementById("output");
                let canvas = document.createElement('canvas')
                let img = document.createElement('img');
                video = document.getElementById('reviewVideo0');
                // console.log(video);
                video.setAttribute('crossOrigin', 'anonymous');
                canvas.width = video.clientWidth;
                canvas.height = video.clientHeight;
                video.onloadeddata = (() => {
                    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
                    let dataURL = canvas.toDataURL('image/png');
                    img.src = dataURL;
                    img.width = 400;
                    img.height = 300;
                    output.appendChild(img);
                    video.setAttribute("poster", dataURL);
                })
            },
            // 获取优秀作业列表
            async getExcellentWork() {
                this.workList = []
                let params = {
                    projectId: this.$route.params.id,
                    page: 1,
                    limit: 5,
                }
                let resData = await this.$Api.Project.getExcellentWork(params);
                // console.log('resData', resData);
                if (JSON.stringify(resData.data) == '[]' || !resData) return;
                this.workList = resData.data.list;
            },
            // 获取优秀作业列表(分页)
            getWorkList() {
                let params = {
                    projectId: this.$route.params.id,
                    page: this.currentPage,
                    limit: this.limit,
                }
                return new Promise((resolve, reject) => {
                    this.$Api.Project.getExcellentWork(params)
                        .then(res => {
                            // console.log('获取作业列表成功', res);
                            this.allWorkList = this.allWorkList.concat(res.data.list);
                            this.totalPage = res.data.totalPage;
                            resolve(111)
                        })
                        .catch(err => {
                            console.log('获取作业列表失败', err)
                        })
                })

            },
            // 获取研修排行榜
            async getProjectRanking() {
                this.workRank = [];
                let params = {
                    projectId: this.$route.params.id,
                    page: 1,
                    limit: 5,
                }
                let resData = await this.$Api.Project.getProjectRanking(params);
                // console.log(resData);
                if (JSON.stringify(resData.data) == '[]' || !resData) return;
                this.workRank = resData.data.list;
            },
            // 获取研修成果
            async getPreojectResult() {
                this.courseCount = 0;
                this.questCount = 0;
                let params = {
                    projectId: this.$route.params.id,
                }
                let resData = await this.$Api.Project.getPreojectResult(params);
                // console.log(resData);
                if (JSON.stringify(resData.data) == '{}' || !resData) return;
                this.courseCount = resData.data.courseCount;
                this.questCount = resData.data.questCount;
            },
            // 获取云端答疑一级讨论组ID
            async getDiscussionId() {
                let params = {
                    projectId: this.$route.params.id,
                }
                let resData = await this.$Api.Project.getDiscussionId(params);
                // console.log(resData);
                if (resData.code == 500) return;
                this.discussId = resData.data.userGroupId;
                this.discussInfo = resData.data;
                this.getDisccusList(resData.data.userGroupId)
            },
            // 获取云端答疑列表
            async getDisccusList(id) {
                this.questionList = [];
                let params = {
                    courseId: id,
                    page: 1,
                    limit: 3,
                }
                let resData = await this.$Api.Course.getDisccusList(params);
                if (JSON.stringify(resData.data) == '[]' || !resData) return;
                // console.log(resData);
                this.questionList = resData.data.records;
            },
            toQuestList() {
                if (!this.discussId) {
                    this.$message('暂无数据');
                    return;
                }
                this.$router.push({
                    path: `/question/${this.discussId}`,
                    query: {
                        proid: this.$route.params.id,
                    }
                })
                this.$store.commit('getDiscussInfo', {
                    name: `${this.discussInfo.userGroupName}讨论区`,
                    period: this.discussInfo.parentUserGroupName,
                })
            },
            // 去云端答疑详情页
            toQuestionDetail(item) {
                this.$router.push({
                    path: `/question/detail/${item.id}`,
                    query: {
                        listId: this.discussId,
                    }
                })
            },
            // 获取精品课程列表
            async getListBoutique() {
                this.courseList = [];
                let params = {
                    page: 1,
                    limit: 3,
                    projectId: this.$route.params.id,
                }
                let resData = await this.$Api.Project.getListBoutique(params);
                // console.log(resData);
                if (JSON.stringify(resData.data) == '{}' || !resData) return;
                this.courseList = resData.data.list
            },
            // 去精品课程详情页
            toDeatil(item, index) {
                this.$router.push({
                    path: `/course/${item.id}`,
                    query: {
                        proid: this.$route.params.id,
                        index: index,
                    }
                });
            },
            // 去PDF展示页
            toPdfPage(item) {
                // console.log(item);
                if (!item.identifier) {
                    this.$message.error('暂无内容');
                    return;
                }
                this.$store.commit('getPdfUrl', item.identifier)
                this.$store.commit('getPdfName', item.title ? item.title : item.name)

                this.$router.push(`/pdf/${item.identifier}`)
            },
            switchTableData(type) {
                this.tableSwitch = type;
                this.getStatistics(type);
            },
            // 获取统计数据
            async getStatistics(type) {
                if (type == undefined || type == null) return;

                let params = {
                    projectId: this.$route.params.id,
                    type: type // 0学校数据 1地区数据 2项目数据
                }
                let resData = await this.$Api.Project.getStatistics(params);
                console.log('statistics', resData);
                if (JSON.stringify(resData.data) == '[]' || !resData.data) {
                    this.tableData = [];
                    return;
                }
                resData.data.map(item => {
                    item.joinRate = (item.studyUserCount / item.userCount).toFixed(2)
                })
                if (type == 2) {
                    this.learningTitle = resData.data[0].itemName;
                    this.learningList.map(item => {
                        item.number = resData.data[0][item.title];
                        if (item.name == '课程学习时长') {
                            item.number = parseInt(item.number / 60)
                            // console.log(item.number)
                        }
                    })
                    return;
                }
                this.tableData = [];
                let data = resData.data.concat(this.tableData);
                this.tableData = deepClone(data);
                if (JSON.stringify(this.tableData) != '[]' && this.tableData.length > 4) {
                    this.openText = '展开'
                    this.tableHeight = '263px'
                }
            },
            openTable() {
                if (this.tableHeight == `${this.$refs['trueTable'].clientHeight}px`) {
                    this.tableHeight = '263px'
                    this.openText = '展开'
                } else {
                    this.tableHeight = this.$refs['trueTable'].clientHeight + 'px';
                    this.openText = '收起'
                }
            },
            // 上一题
            prev() {
                if (this.currentIndex == 0) {
                    this.$message({
                        message: '当前已经是第一个作业',
                        type: 'warning'
                    });
                } else {
                    this.currentIndex -= 1;
                    this.currentWork = this.allWorkList[this.currentIndex];
                }
            },
            next() {
                console.log('currentIndex', this.currentIndex);
                if (this.currentIndex < this.allWorkList.length - 1) {
                    this.currentIndex += 1;
                    this.currentWork = this.allWorkList[this.currentIndex];
                } else if (this.currentIndex == this.allWorkList.length - 1) {
                    if (this.currentPage == this.totalPage) {
                        this.$message({
                            message: '当前已经是最后一个作业',
                            type: 'warning'
                        });
                    } else {
                        this.currentPage += 1;
                        this.getWorkList()
                            .then(res => {
                                console.log(res);
                                this.currentIndex += 1;
                                this.currentWork = this.allWorkList[this.currentIndex];
                            })
                    }

                }
            }

        },
        mounted() {
            this.getSecondList();
            this.getNoticeList();
            this.getProjectBriefing();
            this.getProjectFile();
            this.getExcellentWork();
            this.getProjectRanking();
            this.getPreojectResult();
            this.getDiscussionId();
            this.getListBoutique();
            this.getWorkList();
            this.getStatistics(2);
            this.getStatistics(1);
        },
        activated() {

        },
    }
</script>

<style lang="less" scoped>
    @import "./less/mainPage.less";
</style>